import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Oat Milk`}</h1>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Water`}</strong>{`, 3 cups`}</li>
      <li parentName="ul"><strong parentName="li">{`Oats`}</strong>{`, 1/2 cup, rolled`}</li>
      <li parentName="ul"><strong parentName="li">{`Maple syrup`}</strong>{`, 2 teaspoons`}</li>
      <li parentName="ul"><strong parentName="li">{`Vanilla extract`}</strong>{`, 1/2 teaspoon`}</li>
      <li parentName="ul"><strong parentName="li">{`Sea salt`}</strong>{`, 1/8th teaspoon`}</li>
    </ul>
    <p>{`Add the oats and filtered water to Vitamix, blend for 30 seconds until creamy.`}</p>
    <p>{`Strain through mesh into bowl. Discard excess liquid rather than pressing.`}</p>
    <p>{`Chill.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      